var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"amazon"},[_c('ConfirmDialog',{attrs:{"title":_vm.dialogTitle,"text":_vm.dialogText,"show":_vm.confirmDialog},on:{"close":_vm.closeDelDialog,"confirm":_vm.deleteOffer}}),_c('FullScreenDialog',{attrs:{"title":"Gerir Ofertas","dialog":_vm.fullScreenDialog},on:{"close":_vm.closeFullScreenDialog}},[(_vm.fullScreenDialog)?_c('EditOffer',{attrs:{"edit-offer":_vm.selectedOffer},on:{"close":_vm.closeFullScreenDialog}}):_vm._e()],1),_c('v-col',{staticClass:"text-end"},[_c('div',{staticClass:"my-2"},[_c('v-btn',{attrs:{"color":"primary","small":""},on:{"click":_vm.createOffer}},[_vm._v(" Adicionar Oferta ")])],1)]),_c('h3',[_vm._v("Ofertas Ativas")]),_c('br'),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.activeOffers,"items-per-page":10},scopedSlots:_vm._u([{key:"item.thumbnail",fn:function(ref){
var item = ref.item;
return [_c('v-img',{attrs:{"src":item.thumbnail,"aspect-ratio":"1"}})]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(("R$ " + (item.price.toFixed(2).replace(".", ","))))+" ")]}},{key:"item.info",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('a',_vm._g({staticClass:"m-small",staticStyle:{"text-decoration":"none"},attrs:{"href":item.link,"target":"_blank","rel":"noopener noreferrer"}},on),[_c('v-icon',{attrs:{"color":"blue"}},[_vm._v("mdi-link-variant")])],1)]}}],null,true)},[_c('span',[_vm._v("Link da Oferta")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"m-small",attrs:{"icon":"","color":"#ffa500"}},on),[(item.featured)?_c('v-icon',[_vm._v(" mdi-star ")]):_c('v-icon',[_vm._v(" mdi-star-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Em Destaque")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"m-small",attrs:{"icon":"","color":item.daily ? 'green' : 'red'}},on),[_c('v-icon',[_vm._v("mdi-calendar-star")])],1)]}}],null,true)},[_c('span',[_vm._v("Oferta do Dia")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"m-small",attrs:{"icon":"","color":item.notified_on_telegram ? 'green' : 'red'}},on),[_c('v-icon',[_vm._v("mdi-send-circle")])],1)]}}],null,true)},[_c('span',[_vm._v("Notificado no Telegram")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"m-small",attrs:{"icon":"","color":item.notified_on_whatsapp ? 'green' : 'red'}},on),[_c('v-icon',[_vm._v("mdi-whatsapp")])],1)]}}],null,true)},[_c('span',[_vm._v("Notificado no WhatsApp")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"m-small",attrs:{"icon":"","color":item.notified_on_messaging ? 'green' : 'red'}},on),[(item.notified_on_messaging)?_c('v-icon',[_vm._v(" mdi-bell ")]):_c('v-icon',[_vm._v(" mdi-bell-off ")])],1)]}}],null,true)},[_c('span',[_vm._v("Notificado no 'Messaging'")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"m-small",attrs:{"icon":"","color":item.locked ? 'green' : 'red'}},on),[(item.locked)?_c('v-icon',[_vm._v(" mdi-lock ")]):_c('v-icon',[_vm._v(" mdi-lock-open ")])],1)]}}],null,true)},[_c('span',[_vm._v("Status de Bloqueio")])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"blue"},on:{"click":function($event){return _vm.editOffer(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"icon":"","color":"red"},on:{"click":function($event){return _vm.showDeleteDialog(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}])}),_c('br'),_c('h3',[_vm._v("Ofertas Inativas")]),_c('br'),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.inactiveOffers,"items-per-page":10},scopedSlots:_vm._u([{key:"item.thumbnail",fn:function(ref){
var item = ref.item;
return [_c('v-img',{attrs:{"src":item.thumbnail,"aspect-ratio":"1"}})]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(("R$ " + (item.price.toFixed(2).replace(".", ","))))+" ")]}},{key:"item.info",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('a',_vm._g({staticClass:"m-small",staticStyle:{"text-decoration":"none"},attrs:{"href":item.link,"target":"_blank","rel":"noopener noreferrer"}},on),[_c('v-icon',{attrs:{"color":"blue"}},[_vm._v("mdi-link-variant")])],1)]}}],null,true)},[_c('span',[_vm._v("Link da Oferta")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"m-small",attrs:{"icon":"","color":"#ffa500"}},on),[(item.featured)?_c('v-icon',[_vm._v(" mdi-star ")]):_c('v-icon',[_vm._v(" mdi-star-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Em Destaque")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"m-small",attrs:{"icon":"","color":item.daily ? 'green' : 'red'}},on),[_c('v-icon',[_vm._v("mdi-calendar-star")])],1)]}}],null,true)},[_c('span',[_vm._v("Oferta do Dia")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"m-small",attrs:{"icon":"","color":item.notified_on_telegram ? 'green' : 'red'}},on),[_c('v-icon',[_vm._v("mdi-send-circle")])],1)]}}],null,true)},[_c('span',[_vm._v("Notificado no Telegram")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"m-small",attrs:{"icon":"","color":item.notified_on_whatsapp ? 'green' : 'red'}},on),[_c('v-icon',[_vm._v("mdi-whatsapp")])],1)]}}],null,true)},[_c('span',[_vm._v("Notificado no WhatsApp")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"m-small",attrs:{"icon":"","color":item.notified_on_messaging ? 'green' : 'red'}},on),[(item.notified_on_messaging)?_c('v-icon',[_vm._v(" mdi-bell ")]):_c('v-icon',[_vm._v(" mdi-bell-off ")])],1)]}}],null,true)},[_c('span',[_vm._v("Notificado no 'Messaging'")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"m-small",attrs:{"icon":"","color":item.locked ? 'green' : 'red'}},on),[(item.locked)?_c('v-icon',[_vm._v(" mdi-lock ")]):_c('v-icon',[_vm._v(" mdi-lock-open ")])],1)]}}],null,true)},[_c('span',[_vm._v("Status de Bloqueio")])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"blue"},on:{"click":function($event){return _vm.editOffer(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"icon":"","color":"red"},on:{"click":function($event){return _vm.showDeleteDialog(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }