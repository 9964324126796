
























































































































































































































































































import Vue from "vue";
import { mapGetters } from "vuex";
import ConfirmDialog from "../../components/ConfirmDialog.vue";
import FullScreenDialog from "../../components/FullScreenDialog.vue";
import EditOffer from "./components/Edit.vue";
import { Offer } from "@/store/types";

export default Vue.extend({
  name: "Amazon",

  components: {
    ConfirmDialog,
    FullScreenDialog,
    EditOffer
  },

  data() {
    return {
      confirmDialog: false as Boolean,
      fullScreenDialog: false as Boolean,
      dialogTitle: "" as string,
      dialogText: "" as string,
      selectedOffer: null as unknown | Offer,
      headers: [
        {
          text: "Título",
          align: "start",
          value: "name",
          width: "400"
        },
        { text: "Imagem", value: "thumbnail", sortable: false },
        { text: "Loja", value: "store.name", align: "center" },
        { text: "Preço", value: "price", align: "center" },
        {
          text: "Informações",
          value: "info",
          align: "center",
          width: "250",
          sortable: false
        },
        {
          text: "Ações",
          value: "actions",
          width: "110",
          align: "center",
          sortable: false
        }
      ] as Array<Object>
    };
  },

  computed: {
    ...mapGetters({
      activeOffers: "amazon/getActive",
      inactiveOffers: "amazon/getInactive"
    })
  },

  mounted() {
    this.$store.dispatch("amazon/fetchActive");
    this.$store.dispatch("amazon/fetchInactive");
  },

  methods: {
    createOffer(): void {
      this.openFullScreenDialog();
    },

    editOffer(offer: string): void {
      this.selectedOffer = offer;
      this.openFullScreenDialog();
    },

    deleteOffer(): void {
      this.$store.dispatch("amazon/delete", this.selectedOffer);
      this.closeDelDialog();
    },

    openFullScreenDialog(): void {
      this.fullScreenDialog = true;
    },

    closeFullScreenDialog(): void {
      this.selectedOffer = null;
      this.fullScreenDialog = false;
    },

    showDeleteDialog(offer: Offer): void {
      this.confirmDialog = true;
      this.dialogTitle = "Deseja apagar a oferta?";
      this.dialogText = "Essa ação não pode ser revertida!";
      this.selectedOffer = offer;
    },

    closeDelDialog(): void {
      this.selectedOffer = null;
      this.confirmDialog = false;
    }
  }
});
